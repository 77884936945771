<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Statut de prêt
            </span><br>
      <span v-if="selectedDossier !== null" class="body-2">
        {{ selectedDossier.statut_pret}}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le conducteur des travaux</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-select item-value="value" item-text="value" :items="statut_pret_types" label="Statut de prêt"
                          v-model="statutPret"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateStatutPret">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'StatutPret',
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      statutPret: this.selectedDossier?.statut_pret,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async updateStatutPret() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, statut_pret: this.statutPret});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Statut du prêt mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  },
  computed: {
    statut_pret_types() {
      return this.$store.getters["select/statut_pret_types"];
    },
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>