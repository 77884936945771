<template>
  <div>
    <v-simple-table dense>
      <template>
        <thead>
        <tr>
          <th>Enveloppe</th>
          <th>Date de règlement</th>
          <th>Entreprise</th>
          <th>Montant</th>
          <th>Date de la facture</th>
          <th>Référence de la facture</th>

          <th>Statut</th>
          <th>Justificatif</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="reglement in reglements" :key="reglement.id">
          <td>{{ reglement.saisie_source }}</td>
          <td>{{ getFormattedDate(reglement.date_reglement) }}</td>
          <td>{{ reglement.entreprise_name }}</td>
          <td>{{ reglement.montant }} €</td>
          <td>{{ getFormattedDate(reglement.date_facture) }}</td>
          <td>{{ reglement.reference_facture }}</td>
          <td>
            <reglement-status-chip :reglement="reglement"></reglement-status-chip>
          </td>
          <td>
            <v-btn target="_blank" :href="getUrl(reglement.file)" icon color="primary" v-if="reglement.file">
              <v-icon>mdi-paperclip</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn color="red" v-if="reglement.status === 'waiting'" :disabled="deleting" icon @click="deleteReglement(reglement)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <ReglementForm :icon="true" :reglement="reglement" @reload="reload"
                           :saisie="{'type': 'Décaissement', 'id': reglement.saisie, 'source': reglement.saisie_source}"
                           operation="update"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row justify="end" no-gutters>
      <FactureEtudeForm :dossier="dossier"/>
    </v-row>
  </div>
</template>
<script>
import ReglementForm from "@/views/ReglementForm.vue";
import {computed, ref} from "vue";
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import {TYPES_DECAISSEMENT_ETUDE} from "@/constants/Financement";
import {ReglementRepository} from "@/repository/reglement";
import * as config from "@/config.json";
import FactureEtudeForm from "@/components/finances/FactureEtudeForm.vue";

export default {
  name: 'SaisieFactureInstructeur',
  components: {FactureEtudeForm, ReglementStatusChip, ReglementForm},
  mixins: [DateUtilMixin],
  props: {
    dossier: Object,
  },
  setup(props) {
    const deleting = ref(false);
    function isInEtude(source) {
      return TYPES_DECAISSEMENT_ETUDE.includes(source);
    }
    let reglements = computed(() => props.dossier.saisies
        .map(saisie => saisie.reglements)
        .reduce((a, b) => a.concat(b), []).filter(reglement => isInEtude(reglement.saisie_source)));
    async function deleteReglement(reglement) {
      this.deleting = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.delete(reglement);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Règlement supprimé !");
          this.$emit("reload");
        }
      } finally {
        this.deleting = false;
      }
    }
    function getUrl(path) {
      return config.BASE_URL + path;
    }
    function reload() {
      this.$emit('reload');
    }

    return {reglements, deleteReglement, deleting, getUrl, reload};
  }
}
</script>
<style>

.timeline > ol > li:last-child {
  display: none !important;
}

</style>