<template>
  <v-row>
    <v-col cols="10">
      <span class="body-1">
        Date de paiement de l'enquêteur
      </span><br>
      <span v-if="selectedDossier.date_paiement_enqueteur" class="body-2">
        {{ getFormattedDate(selectedDossier.date_paiement_enqueteur) }}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier la date de paiement</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-menu v-model="menu_date" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                        :close-on-click="true">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-on="on" v-bind="attrs" label="Date de paiement de l'enquêteur"
                                  :value="getFormattedDate(datePaiementEnqueteur) || ''"></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker v-model="datePaiementEnqueteur"></v-date-picker>
                    <v-card-actions>
                      <v-btn @click="menu_date = false" text color="primary">Fermer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateDatePaiementEnqueteur">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'DateArrete',
  mixins: [DateUtilMixin],
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      dialog: false,
      menu_date: false,
      datePaiementEnqueteur: null,
    }
  },
  methods: {
    async updateDatePaiementEnqueteur() {
      let old = this.selectedDossier.date_paiement_enqueteur;
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, date_paiement_enqueteur: this.datePaiementEnqueteur});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Date de paiement de l'enquêteur mis à jour.");
        this.dialog = false;
        await this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selectedDossier.id,
          message: `Mise à jour de la date de paiement de l'enquêteur - ${this.datePaiementEnqueteur} (Anciennement: ${old})`,
        })
      }
      this.dialog = false;
    }
  }
}
</script>
<style>
</style>