<template>
  <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="operation === 'create'" class="white--text">Créer le bien immobilier</v-toolbar-title>
        <v-toolbar-title v-else class="white--text">Modifier le bien immobilier</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="biForm">
          <v-row>
            <v-col cols="6">
              <v-select :items="type_bien_select"  item-text="name" item-value="value" label="Type de bien" v-model="bienImmobilierModel.type_bien"> </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Année de construction" type="number" min="1950" max="2050" step="1" v-model="bienImmobilierModel.annee_construction"> </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select :items="type_assainissement_select" item-text="name" item-value="value" label="Type d'assainissement" v-model="bienImmobilierModel.assainissement"> </v-select>
            </v-col>
            <v-col cols="6">
              <v-select :items="nbre_etage_select" item-text="name" item-value="value" label="Nombre d'étages" v-model="bienImmobilierModel.nbre_etage"> </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" min="1" max="10" step="1" label="Nombre de pièces" v-model="bienImmobilierModel.nbre_pieces"> </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select :items="type_pprn_select" item-text="name" item-value="value" label="PPRN" v-model="bienImmobilierModel.pprn"> </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Zone PLU" v-model="bienImmobilierModel.zone_plu"> </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Référence cadastrale" v-model="bienImmobilierModel.ref_cadastrale"> </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox label="Bénéficiaire propriétaire du logement" v-model="bienImmobilierModel.proprio_logement"> </v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox label="Bénéficiaire propriétaire du terrain" v-model="bienImmobilierModel.proprio_terrain"> </v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Surface brute  m²" v-model="bienImmobilierModel.surface_brute"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Surface habitable  m²" v-model="bienImmobilierModel.surface_habitable"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Surface plancher  m²" v-model="bienImmobilierModel.surface_plancher"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Latitude" v-model="bienImmobilierModel.latitude"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Longitude" v-model="bienImmobilierModel.longitude"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox label="Déclaration préalable" v-model="bienImmobilierModel.declaration_prealable"> </v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox label="Diagnostic BET" v-model="bienImmobilierModel.diagnostic_bet"> </v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox label="SPANC" v-model="bienImmobilierModel.spanc"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="createBienImmobilier" v-if="operation ==='create'">Créer</v-btn>
        <v-btn color="primary" @click="updateBienImmobilier" v-if="operation ==='update'">Modifier</v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import {convertSelectModelToSelct, convertToSelect} from "@/util";
import {TYPES_PPRN} from "@/constants/BienImmobilier";

export default {
  name: 'BienImmobilierForm',
  props: {
    bien_immobilier: {type:Object, default:null},
    operation: {type: String, default: "create"},
    dossier_id: {type: Number, default: null}
  },
  async mounted() {
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  data() {
    let bienImmobilierModel = this.bien_immobilier !== null ? JSON.parse(JSON.stringify(this.bien_immobilier)) : {
        "type_bien": "",
        "ref_cadastrale": "",
        "pprn": null,
        "zone_plu": "",
        "surface_brute": null,
        "surface_plancher": null,
        "surface_habitable": null,
        "annee_construction": null,
        "nbre_etage": "",
        "nbre_pieces": null,
        "assainissement": null,
        "proprio_terrain": false,
        "proprio_logement": false,
        "dossier": null,
        "latitude": null,
        "longitude": null,
        "declaration_prealable": false,
        "diagnostic_bet": false,
        "spanc": false,
    };
    return {
      bienImmobilierModel,
    }
  },
  computed: {
    type_assainissement_select() {
      return convertSelectModelToSelct(this.$store.getters["select/types_assainissement"])
    },
    type_bien_select() {
      return convertSelectModelToSelct(this.$store.getters["select/types_biens"])
    },
    nbre_etage_select() {
      return convertSelectModelToSelct(this.$store.getters["select/nombre_etage"])
    },
    type_pprn_select() {
      return convertToSelect(TYPES_PPRN);
    }
  },
  methods: {
    async updateBienImmobilier() {
      let result = await this.$store.dispatch("bien_immobilier/patchBienImmobilier", this.bienImmobilierModel);
      if(result) await this.$store.dispatch("annonce/annonceSuccess", "Bien immobilier modifié");
      this.$emit("close-dialog");
    },
    async createBienImmobilier() {
      this.bienImmobilierModel.dossier = this.dossier_id;
      let result = await this.$store.dispatch("bien_immobilier/postBienImmobilier", this.bienImmobilierModel);
      if(result !== null) await this.$store.dispatch("annonce/annonceSuccess", "Bien immobilier créé");
      this.$emit("close-dialog");
    }
  }
}
</script>