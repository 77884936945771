<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Nom de l'instructeur DEAL
            </span><br>
      <span v-if="selected_dossier.instructeur_deal" class="body-2">
              {{ selected_dossier.instructeur_deal }}
            </span>

      <span v-else class="body-2">
              Non défini
            </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le nom de l'instructeur DEAL</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field label="Instructeur DEAL" v-model="instructeurDeal"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateInstructeurDeal">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'InstructeurDEAL',
  props: {
    selected_dossier: {},
  },
  data() {
    return {
      dialog: false,
      instructeurDeal: this.selected_dossier.instructeur_deal,
    }
  },
  methods: {
        async updateInstructeurDeal() {
      let oldDeal = this.selected_dossier.instructeur_deal;
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selected_dossier.id, instructeur_deal: this.instructeurDeal});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Instructeur DEAL mis à jour.");
        this.dialog = false;
        await this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selected_dossier.id,
          message: `Mise à jour de l'instructeur DEAL - ${this.instructeurDeal} (Anciennement: ${oldDeal})`,
        })
      }
      this.dialog = false;
    },
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>