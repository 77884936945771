<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">
        Ajouter une facture
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" class="white--text">
        <v-toolbar-title class="white--text">Ajouter une facture au dossier</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select v-model="saisieSource" :items="types_decaissement" label="Source"></v-select>
        <span v-if="saisieSource === 'HONORAIRES CONDUCTEUR DE TRAVAUX'" class="body-2 primary--text"><v-icon class="primary--text">mdi-information</v-icon> Note : Penser à recalculer le montant TTC pour les honoraires de conducteurs de travaux.</span>
        <v-select label="Entreprise" v-model="reglementEntreprise" :items="entreprises" item-value="id" item-text="name"></v-select>
        <v-text-field label="Montant" v-model="reglementMontant" type="number"></v-text-field>
        <date-field label="Date de règlement" v-model="reglementDate"></date-field>
        <date-field label="Date de la facture" v-model="factureDate"></date-field>
        <v-text-field v-model="reglementReference" label="Référence de la facture"></v-text-field>
        <v-select label="Statut" :items="statuses" disabled item-text="name" item-value="value" v-model="reglementStatus"></v-select>
        <v-file-input v-model="reglementFile" label="Facture lié"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" v-if="creating">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-row>
        <v-btn @click="createFacturePayment" :disabled="creating" color="primary">
          Créer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {TYPES_DECAISSEMENT_ETUDE} from "@/constants/Financement";
import DateField from "@/components/base/DateField.vue";
import {ReglementRepository} from "@/repository/reglement";

export default {
  name: 'FactureEtudeForm',
  components: {DateField},
  props: {
    dossier: Object,
  },
  async mounted() {
    if (this.$store.getters["entreprises/fetch_status"] === "INITIAL") {
      await this.$store.dispatch("entreprises/fetchEntreprises");
    }
  },
  data() {
    return {
      saisieSource: null,
      reglementDate: null,
      reglementStatus: "waiting",
      reglementEntreprise: null,
      reglementFile: null,
      factureDate: null,
      useBonAsFacture: null,
      reglementMontant: null,
      reglementReference: null,
      dialog: false,
      creating: false,
      statuses: [{value: 'waiting', name: 'En attente'}],
      entrprises: [],
    }
  },
  computed: {
    types_decaissement() {
      return TYPES_DECAISSEMENT_ETUDE;
    },
    entreprises() {
      return this.$store.getters["entreprises/entreprises"];
    },
  },
  methods: {
    async createFacturePayment() {
      this.creating = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.createFacturePayment({
          source: this.saisieSource,
          date: this.reglementDate,
          date_facture: this.factureDate,
          reference_facture: this.reglementReference,
          montant: this.reglementMontant,
          status: this.reglementStatus,
          dossier: this.dossier.id,
          entreprise: this.reglementEntreprise,
          file: this.reglementFile,
        });
        if (result) {
          this.$emit("reload");
          await this.$store.dispatch("annonce/annonceSuccess", ".");
        }
      } finally {
        this.creating = false;
        this.dialog = false;
      }
    }
  }
}
</script>