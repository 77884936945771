<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Numéro d'arrêté CTM
            </span><br>
      <span v-if="selected_dossier.numero_arrete_ctm" class="body-2">
              {{ selected_dossier.numero_arrete_ctm }}
            </span>

      <span v-else class="body-2">
              Non défini
            </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le numéro d'arrêté CTM</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field label="Numéro d'arrêté ctm" v-model="numeroArreteCTM"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateNumeroArreteCTM">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'NumeroArreteCTM',
  props: {
    selected_dossier: {},
  },
  data() {
    return {
      dialog: false,
      numeroArreteCTM: this.selected_dossier.numero_arrete_ctm,
    }
  },
  methods: {
    async updateNumeroArreteCTM() {
      let oldNumeroArreteCTM = this.selected_dossier.numero_arrete_ctom;
      let result = await this.$store.dispatch("dossier/patchDossier", {
        id: this.selected_dossier.id,
        numero_arrete_ctm: this.numeroArreteCTM
      });
      if (result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Numéro d'arrêté CTM mis à jour.");
        this.dialog = false;
        await this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selected_dossier.id,
          message: `Mise à jour du numéro d'arrêté - ${this.numeroArreteCTM} (Anciennement: ${oldNumeroArreteCTM})`,
        })
      }
      this.dialog = false;
    }
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>