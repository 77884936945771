<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Retour DEAL
            </span><br>
      <span v-if="loaded && commentRetourDeal === null" class="body-2">
        Non défini
      </span>
      <pre v-else-if="loaded && commentRetourDeal !== null" style=" margin:0; font-family: Roboto,serif; white-space: pre-wrap">{{ messageRetourDeal }}</pre>
      <span v-else class="body-2">
        Chargement..
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Retour DEAL</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-textarea v-model="message" label="Retour DEAL"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateRetourDeal">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'RetourDEAL',
  props: {
    selectedDossier: Object,
  },
  async mounted() {
    let commentaires = await this.$store.dispatch("dossier/commentaires/getByDossierId", this.selectedDossier);
    this.commentRetourDeal = commentaires.find((commentaire) => commentaire.message.startsWith("Retour DEAL"));
    if (this.commentRetourDeal === undefined) {
      this.commentRetourDeal = null;
    } else {
      let sourceMessage = this.commentRetourDeal.message.split("\n", 2);
      this.message = sourceMessage.length === 2 ? sourceMessage[1] : sourceMessage[0];
    }
    this.loaded = true;
  },
  data() {
    return {
      commentRetourDeal: null,
      message: null,
      loaded: false,
      dialog: false,
      menu_dialog: false,
    }
  },
  computed: {
    messageRetourDeal() {
      let sourceMessage = this.commentRetourDeal.message.split("\n", 2);
      return sourceMessage.length === 2 ? sourceMessage[1] : sourceMessage[0];
    }
  },
  methods: {
    async updateRetourDeal() {
      if (this.commentRetourDeal === null) {
        let comment = await this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selectedDossier.id,
          message: "Retour DEAL:\n" + this.message,
        });
        if (comment !== null) {
          this.$store.dispatch("annonce/annonceSuccess", "Retour DEAL mis à jour");
          this.dialog = false;
          this.commentRetourDeal = comment;
        }
      } else {
        let comment = await this.$store.dispatch("dossier/commentaires/patchCommentaire", {
          ...this.commentRetourDeal,
          message: "Retour DEAL:\n" + this.message
        });
        if (comment !== null) {
          this.$store.dispatch("annonce/annonceSuccess", "Retour DEAL mis à jour");
          this.dialog = false;
          this.commentRetourDeal = comment;
        }
      }
    }
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>