<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Referent Martinique Habitat
            </span><br>
      <span v-if="selectedDossier !== null && selectedDossier.referent_mq_habitat !== null" class="body-2">
        {{ selectedDossier.referent_mq_habitat }}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le référent Martinique Habitat</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field label="Référent Martinique Habitat"
                          v-model="referentMqHabitat"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateReferentMqHabitat">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ReferentMqHabitat',
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      referentMqHabitat: this.selectedDossier?.referent_mq_habitat,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async updateReferentMqHabitat() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, referent_mq_habitat: this.referentMqHabitat});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Référent Martinique Habitat mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  },
  computed: {
  }
}
</script>
<style>
</style>