<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Modifier le bénéficiaire</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="benefForm">
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.contact.prenom" :rules="[notNullNorEmpty]"
                          label="Prénom"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.contact.nom" :rules="[notNullNorEmpty]" label="Nom"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.contact.email" type="email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select :items="titre_select" :rules="[notNullNorEmpty]" item-value="value" item-text="name"
                      v-model="beneficiaireModel.contact.titre"
                      label="Titre"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select :items="situation_matrimoniale_select" item-value="value" item-text="name"
                      v-model="beneficiaireModel.situation_matrimoniale" label="Situation matrimoniale"></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.profession" label="Profession"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.contact.tel_fixe" label="Téléphone fixe"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.contact.tel_mobile" label="Téléphone mobile"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select :rules="[notNullNorEmpty]"
                      @change="beneficiaireModel.code_postal = VILLES['Martinique'][beneficiaireModel.ville]"
                      :items="villes" item-value="value" item-text="name" v-model="beneficiaireModel.ville"
                      label="Ville"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select @change="beneficiaireModel.ville = VILLES['Martinique'][beneficiaireModel.code_postal]"
                      :items="codes_postaux" item-text="name" item-value="value" v-model="beneficiaireModel.code_postal"
                      label="Code postal"></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.adresse" label="Adresse"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.lieu_naissance" label="Lieu de naissance"></v-text-field>
          </v-col>


          <v-col cols="6">
            <v-menu v-model="menu_date_naissance" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date de naissance"
                              :value="getFormattedDate(beneficiaireModel.date_naissance)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="beneficiaireModel.date_naissance"></v-date-picker>
                <v-card-actions>
                  <v-btn @click="menu_date_naissance = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="beneficiaireModel.numero_secu" label="Numéro de sécurité sociale"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number" v-model="beneficiaireModel.epargne" label="Épargne"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" v-model="beneficiaireModel.nbre_occupants" label="Nombre d'occupants"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" v-model="beneficiaireModel.nbre_enfants_a_charge" label="Nombre d'enfants à charge"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" step="0.1" v-model="beneficiaireModel.nbre_part_fiscale" label="Part fiscale"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" step="0.01" v-model="beneficiaireModel.revenus_n1" label="Revenus N-1"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" step="0.01" v-model="beneficiaireModel.revenus_n2" label="Revenus N-2"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="beneficiaireModel.handicap" label="Est en situation de handicap"></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="beneficiaireModel.eligible_apa" label="Est éligible à l'APA"></v-checkbox>
          </v-col>
          <v-col cols="6">
              <v-text-field  v-model="beneficiaireModel.numero_allocataire" label="Numéro allocataire"></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field type="number" step="0.01" v-model="beneficiaireModel.pret_caf" label="Prêt CAF (si applicable)"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="createBeneficiaire" :disabled="changing" v-if="operation ==='create'">Créer</v-btn>
      <v-btn color="primary" @click="updateBeneficiaire" v-if="operation ==='update'">Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import {convertSelectModelToSelct, convertStringListToSelect, convertToSelect} from "@/util";
import {SEXE} from "@/constants/Beneficiaire";
import dayjs from "dayjs";
import {VILLES} from "@/constants/Villes";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'BeneficiaireForm',
  mixins: [ValidationRulesMixin],
  props: {
    beneficiaire: {type: Object, default: null},
    operation: {type: String, default: "create"},
    dossier_id: {type: Number, default: null}
  },
  async mounted() {
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  data() {
    let beneficiaireModel = this.beneficiaire !== null ? JSON.parse(JSON.stringify(this.beneficiaire)) : {
      contact: {
        nom: "",
        prenom: "",
        titre: "",
        tel_mobile: null,
        tel_fixe: null,
        email: null,

      },
      situation_matrimoniale: "",
      profession: "",
      adresse: "",
      ville: "",
      code_postal: null,
      numero_secu: "",
      date_naissance: null,
      lieu_naissance: "",
      handicap: "",
      epargne: null,
      nbre_occupants: null,
      nbre_enfants_a_charge: null,
      part_fiscale: null,
      nbre_part_fiscale: null,
      revenus_n1: null,
      revenus_n2: null,
      eligible_apa: false,
      numero_allocataire: null,
      pret_caf: null,
    };

    return {
      beneficiaireModel,
      menu_date_naissance: false,
      VILLES,
      changing: false,
    };
  },
  computed: {
    sexe_select() {
      return convertToSelect(SEXE);
    },
    titre_select() {
      return convertSelectModelToSelct(this.$store.getters["select/titres"])
    },
    situation_matrimoniale_select() {
      return convertSelectModelToSelct(this.$store.getters["select/statuts_matrimoniaux"])
    },
    codes_postaux() {
      return convertStringListToSelect(Object.values(VILLES["Martinique"]));
    },
    villes() {
      return convertStringListToSelect(Object.keys(VILLES["Martinique"]));
    },
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    }
  },
  methods: {
    getFormattedDate(date) {
      return date ? dayjs(date).format("DD/MM/YYYY") : "";
    },
    async updateBeneficiaire() {
      if (this.$refs.benefForm.validate()) {
        this.changing = true;
        this.cleanFields();
        let result = await this.$store.dispatch("beneficiaire/patchBeneficiaire", this.beneficiaireModel);
        if (result) {
          await this.$store.dispatch("annonce/annonceSuccess", "Bénéficiaire modifié");
          this.$emit("close-dialog");
          this.$store.dispatch("dossier/fetchDossierById", this.dossier_id)
        }
        this.changing = false;
      }
    },
    async createBeneficiaire() {
      if (this.$refs.benefForm.validate()) {
        this.changing = true;
        this.cleanFields();
        let result = await this.$store.dispatch("beneficiaire/postBeneficiaire", this.beneficiaireModel);
        if (result) {
          await this.$store.dispatch("annonce/annonceSuccess", "Bénéficiaire créé");
          this.$store.dispatch("dossier/commentaires/postCommentaire", {
            dossier: this.dossier_id,
            message: `Création d'un bénéficiaire pour le dossier ${this.selected_dossier.reference} nommé `
                + `${result.contact.prenom} ${result.contact.nom}`,
          })
          this.$emit("close-dialog");
          this.$store.dispatch("dossier/fetchDossierById", this.dossier_id);
        }
        this.changing = false;

      }
    },
    cleanFields() {
      delete this.beneficiaireModel.referent;
      let prenom = this.beneficiaireModel.contact.prenom;
      this.beneficiaireModel.contact.prenom = prenom.charAt(0).toUpperCase() + prenom.slice(1);

      let nom = this.beneficiaireModel.contact.nom;
      this.beneficiaireModel.contact.nom = nom.toUpperCase();

      this.beneficiaireModel.dossier = this.dossier_id;
    }
  }
}
</script>