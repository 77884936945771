<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Date d'arrêté
            </span><br>
      <span v-if="selectedDossier.date_arrete" class="body-2">
              {{ getFormattedDate(selectedDossier.date_arrete) }}
      </span>

      <span v-else class="body-2">
        Non défini
        <span class="orange--text"
              v-if="selectedDossier.statut.numerical_value >= 11 && (!(selectedDossier.date_arrete) || !(selectedDossier.numero_arrete))">
          <br><v-icon color="orange" large>mdi-exclamation</v-icon> La date (ou le numéro) d'arrêté n'est pas encore entré pour ce
          dossier qui a déjà passé l'étape 11.
        </span>
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier la date d'arrêté</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-menu v-model="menu_date_arrete" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                        :close-on-click="true">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-on="on" v-bind="attrs" label="Date d'arrêté"
                                  :value="getFormattedDate(dateArrete) || ''"></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker v-model="dateArrete"></v-date-picker>
                    <v-card-actions>
                      <v-btn @click="menu_date_arrete = false" text color="primary">Fermer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateDateArrete">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'DateArrete',
  mixins: [DateUtilMixin],
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      dialog: false,
      menu_date_arrete: false,
      dateArrete: this.selectedDossier.date_arrete,
    }
  },
  methods: {
    async updateDateArrete() {
      let oldDeal = this.selectedDossier.date_arrete;
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, date_arrete: this.dateArrete});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Date d'arrêté mis à jour.");
        this.dialog = false;
        await this.$store.dispatch("dossier/commentaires/postCommentaire", {
          dossier: this.selectedDossier.id,
          message: `Mise à jour de la date d'arrêté - ${this.dateArrete} (Anciennement: ${oldDeal})`,
        })
      }
      this.dialog = false;
    }
  }
}
</script>
<style>
</style>