<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Modifier la pièce jointe</v-toolbar-title>
    </v-toolbar>
      <v-card-text>
          <date-field label="Date d'expiration" v-model="dateExpiration"></date-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="updateAttachment" :disabled="updating" color="primary" class="white--text">Modifier</v-btn>
      </v-card-actions>
  </v-card>
  </v-dialog>

</template>
<script>
import DateField from "@/components/base/DateField.vue";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
export default {
  name: 'EditAttachmentDialog',
  components: {DateField},
  mixins: {ValidationRulesMixin},
  props: {
    attachment: Object,
  },
  data() {
    return {
      dateExpiration: this.attachment.date_expiration,
      updating: false,
      dialog: false,
    }
  },
  methods: {
    async updateAttachment() {
      this.updating = true;
      await this.$store.dispatch("attachments/patchAttachment", {id: this.attachment.id, date_expiration: this.dateExpiration});
      this.$store.dispatch("annonce/annonceSuccess", "Élément modifié.")
      this.updating = false;
      this.dialog = false;
      this.$emit("reload");
    }
  }

}
</script>