<template>
  <div>
    <v-row no-gutters justify="end" align-content="start">
      <v-btn v-if="isIntern || isMaitreOeuvre" color="primary" @click="createDevis" class="white--text">Créer le devis</v-btn>
    </v-row>
    <v-data-iterator v-if="dossier.devis.length > 0" :items="dossier.devis">
      <template v-slot:item="{item}">
        <v-card :to="'/devis/' + item.id">
          <v-card-title class="primary--text">{{ item.name != null ? item.name : "Devis" }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <span
                  class="grey--text font-italic" v-if="item.created_by">Créé par : {{ item.created_by.first_name }} {{ item.created_by.last_name }}</span>
                <br>
                <span class="grey--text font-italic">Modifié le : {{ getReadableDate(item.date_modification) }}</span><br>
                <span class="grey--text font-italic">Créé le : {{ getReadableDate(item.date_creation) }}</span>
              </v-col>
              <v-col cols="4">
                <span class="blue--text">Montant des travaux</span><br>
                <span class="blue--text">{{ item.total_ttc}} €</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-data-iterator>

  </div>
</template>
<script>

import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'Devis',
  props: {
    dossier: Object,
  },
  mixins: [DateUtilMixin, AuthMixin],
  data() {
    return {
      devis: null,
    };
  },
  computed: {
    logged_user() {
      return this.$store.getters["auth/getLoggedUser"];
    }
  },
  methods: {
    async createDevis() {
      let devis = await this.$store.dispatch("devis/postDevis", {dossier: this.dossier.id, created_by: this.logged_user.id});
      if (devis != null) {
        this.$emit("reload");
       this.$router.push(`/devis/${devis.id}`);
        this.$store.dispatch("annonce/annonceSuccess", "Devis créé");
      }
    }
  }
}
</script>