import { render, staticRenderFns } from "./Referent.vue?vue&type=template&id=5291f67d"
import script from "./Referent.vue?vue&type=script&lang=js"
export * from "./Referent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports