<template>
  <v-card flat elevation="0">
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text" v-if="operation === 'update'">Modifier le référent</v-toolbar-title>
      <v-toolbar-title class="white--text" v-if="operation === 'create'">Créer le référent</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="referentForm">

        <v-row>
          <v-col cols="6">
            <v-select :items="relations_select" item-value="value" item-text="name" v-model="relationModel"
                      label="Relation avec le référent"></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="referentModel.prenom" label="Prénom"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="referentModel.nom" label="Nom"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="referentModel.email" type="email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select :items="sexe_select" item-value="value" item-text="name" v-model="referentModel.sexe"
                      label="Sexe"></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="referentModel.tel_fixe" label="Téléphone fixe"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="referentModel.tel_mobile" label="Téléphone mobile"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="operation === 'create'" color="primary" @click="createReferent" class="white--text">Créer</v-btn>
      <v-btn v-if="operation === 'update'" color="primary" @click="patchReferent" class="white--text">Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {convertToSelect} from "@/util";
import {RELATIONS, SEXE} from "@/constants/Beneficiaire";

export default {
  name: 'ReferentForm',
  props: {
    operation: String,
    referent: Object,
    relation: String,
    beneficiaire_id: Number,
  },
  data() {
    return {
      relationModel: this.relation,
      referentModel: this.operation === "update" ? JSON.parse(JSON.stringify(this.referent)) : {
        "nom": "",
        "prenom": "",
        "sexe": null,
        "email": "",
        "tel_mobile": "",
        "tel_fixe": ""
      },
    }
  },
  computed: {
    sexe_select() {
      return convertToSelect(SEXE);
    },
    relations_select() {
      return convertToSelect(RELATIONS);
    },
  },
  methods: {
    async createReferent() {
      let referent = await this.$store.dispatch("contact/postContact", this.referentModel);
      if(referent === null) {
        return;
      }
      let beneficiaire = await this.$store.dispatch("beneficiaire/patchBeneficiaire", {
        id: this.beneficiaire_id,
        relation_referent: this.relationModel,
        referent: referent.id,
      });
      if(beneficiaire !== null) {
        this.$store.dispatch("annonce/annonceSuccess", "Référent créé");
        this.$emit("close-dialog");
        this.$root.$emit("refresh");
      }
    },
    async patchReferent() {
      let referent = await this.$store.dispatch("contact/patchContact", this.referentModel);
      if(referent === null) {
        return;
      }
      let beneficiaire = await this.$store.dispatch("beneficiaire/patchBeneficiaire", {
        id: this.beneficiaire_id,
        relation_referent: this.relationModel,
        referent: referent.id,
      });
      if(beneficiaire !== null) {
        this.$store.dispatch("annonce/annonceSuccess", "Référent mis à jour.");
        this.$root.$emit("reload");
      }
    },

  }
}
</script>