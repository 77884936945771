import {Api} from "@/repository/api";
import axios from "axios";
import store from "@/store";


export class PassageRepository extends Api{
    constructor() {
        super("passage");
    }

    async fetchByDossierId(id) {
        try {
            let response = await axios.get(`${this.url}/?dossier=${id}`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }
}