<template>
  <div>
    <v-divider></v-divider>
    <span class="h4 grey--text text-capitalize">Mandataire</span>
    <v-row v-if="beneficiaire.referent !== null">
      <v-col cols="6">
        <span class="body-1">Relation avec le bénéficiaire</span><br>
        <span class="body-2">{{ RELATIONS[beneficiaire.relation_referent] }}</span>
      </v-col>
      <v-col cols="6">
        <span class="body-1">Nom</span><br>
        <span class="body-2">{{ beneficiaire.referent.nom }}</span>
      </v-col>
      <v-col cols="6">
        <span class="body-1">Prénom</span><br>
        <span class="body-2">{{ beneficiaire.referent.prenom }}</span>
      </v-col>
      <v-col cols="6">
        <span class="body-1">Email</span><br>
        <span class="body-2">{{ beneficiaire.referent.email }}</span>
      </v-col>

      <v-col cols="6">
        <span class="body-1">Sexe</span><br>
        <span class="body-2">{{ beneficiaire.referent.sexe }}</span>
      </v-col>
      <v-col cols="6">
        <span class="body-1">Téléphone fixe</span><br>
        <span class="body-2">{{ beneficiaire.referent.tel_fixe }}</span>
      </v-col>
      <v-col cols="6">
        <span class="body-1">Téléphone mobile</span><br>
        <span class="body-2">{{ beneficiaire.referent.tel_mobile }}</span>
      </v-col>
    </v-row>
    <v-row class="mt-4 pa-2">
      <v-btn v-if="beneficiaire.referent === null && isIntern" @click="createReferent" color="primary" class="white--text mr-2">Créer le référent</v-btn>
      <v-btn v-if="beneficiaire.referent !== null && isIntern" @click="editReferent" color="primary" class="white--text mr-2">Modifier le référent</v-btn>
      <v-btn v-if="beneficiaire.referent !== null && isIntern" @click="deleteReferent" color="red" class="white--text">Supprimer le référent</v-btn>
    </v-row>
    <v-divider></v-divider>
    <v-dialog max-width="600" v-model="referentDialog">
      <ReferentForm  v-if="isIntern" :operation="operation" :referent="referent"
                    :beneficiaire_id="beneficiaire.id"
                    :relation="beneficiaire.relation_referent" @close-dialog="referentDialog =false" />
    </v-dialog>
  </div>
</template>
<script>
import ReferentForm from "@/views/ReferentForm";
import {RELATIONS} from "@/constants/Beneficiaire";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'Referent',
  components: {ReferentForm},
  mixins: [AuthMixin],
  props: {
    beneficiaire: {
      type: Object,
    }
  },
  data() {
    return {
      operation: "create",
      referent: this.beneficiaire.referent,
      referentDialog: false,
      RELATIONS: RELATIONS,
    };
  },
  computed: {

  },
  methods: {
    createReferent() {
      this.operation = "create";
      this.referent = null;
      this.referentDialog = true;
    },
    editReferent() {
      this.operation = "update";
      this.referent = this.beneficiaire.referent;
      this.referentDialog = true;
    },
    async deleteReferent() {
      await this.$store.dispatch("contact/deleteContact", this.beneficiaire.referent);
      this.$root.$emit("refresh");
      await this.$store.dispatch("annonce/annonceSuccess", "Référent supprimé.")
    }
  }
}
</script>