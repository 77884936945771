import {DOSSIER_ETATS} from "@/constants/DossierStatus";

export const DossierEtatMixin = {
    computed: {
        etats() {
            return DOSSIER_ETATS;
        },
        etats_select() {
            return this.convertToSelect(this.etats);
        }
    },
    methods: {
        convertToSelect(object) {
            let select = []
            for (const key in object) {
                select.push({value: key, label: object[key]});
            }
            return select;
        }
    },
}