<template>
    <v-row>
    <v-col cols="10">
            <span class="body-1">
              Réalisateur de l'étude technique
            </span><br>
      <span v-if="selectedDossier.etude_technique" class="body-2">
        {{ selectedDossier.etude_technique}}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le réalisateur de l'étude technique</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field label="Réalisateur de l'étude technique"
                          v-model="etudeTechnique"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateEtudeTechnique">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'EtudeTechnique',
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      etudeTechnique: this.selectedDossier.etude_technique,
      dialog: false,
    }
  },
  methods: {
    async updateEtudeTechnique() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, etude_technique: this.etudeTechnique});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Étude technique mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  }
}
</script>
<style>

</style>