<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Conducteur des travaux
            </span><br>
      <span v-if="selectedDossier.conducteur_travaux" class="body-2">
        {{ selectedDossier.conducteur_travaux.first_name + " " + selectedDossier.conducteur_travaux.last_name}}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le conducteur des travaux</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-select item-text="fullname" :items="conducteur_travaux" item-value="id" label="Conducteur des travaux"
                          v-model="conducteurTravaux"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateConducteurTravaux">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ConducteurTravaux',
  props: {
    selectedDossier: Object,
  },
  async mounted() {
    this.loading = true;
    let userFetchStatus = this.$store.getters["users/loading_status"];
    if (userFetchStatus === "INITIAL" || userFetchStatus === "ERROR") {
      await this.$store.dispatch("users/fetchUsers");
    }
    this.loading = false;
  },
  data() {
    return {
      conducteurTravaux: this.selectedDossier.conducteur_travaux,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async updateConducteurTravaux() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, conducteur_travaux: this.conducteurTravaux});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Nom du conducteur des travaux mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  },
  computed: {
    conducteur_travaux() {
      return this.$store.getters["users/conducteur_travaux"];
    }
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>