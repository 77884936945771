<template>
<v-dialog v-model="signingDialog" max-width="800">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" @click="startSignatureProcess" v-on="on" v-bind="attrs" class="white--text">
        <span v-if="!hasSignature">{{ buttonText }}</span>
        <span v-else>{{signedButtonText}}</span>
      </v-btn>
    </template>
    <v-card>
      <v-app-bar color="primary">
        <v-toolbar-title class="white--text">Signer directement</v-toolbar-title>
      </v-app-bar>
      <v-row no-gutters justify="center" align="center">
          <canvas class="mt-4 grey-border" ref="signatureCanvas" id="signatureCanvas" :height="height" :width="width"></canvas>
        </v-row>
      <v-card-actions>
        <v-btn color="grey" class="white--text" @click="clearSigningPad">Nettoyer</v-btn>
        <v-btn color="primary" class="white--text" @click="validateSignature">Signer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  name: "SignaturePrompt",
  props: {
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 150,
    },
    buttonText: {
      type: String,
      default: "Signer",
    },
    signedButtonText: {
      type: String,
      default: "Signé",
    },
    value: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      signaturePad: null,
      signingDialog: false,
      hasSignature: false,
    }
  },
  methods: {
    clearSigningPad() {
      if(this.signaturePad !== null) {
        this.signaturePad.clear();
        this.hasSignature = false;
      }
    },
    validateSignature() {
      this.$emit("input", this.signaturePad.toDataURL());
      this.hasSignature = true;
      this.signingDialog = false;
    },
    startSignatureProcess() {
      let that = this;
      this.$nextTick(function () {
        that.$nextTick(function () {
          let canvas = that.$refs.signatureCanvas;
          that.signaturePad = new SignaturePad(canvas);
        })
      })
    }
  }
}
</script>

<style scoped>
.grey-border {
  border: 1px solid grey;
}
</style>