<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">
        Diviser un document
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Diviser un document</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-select v-model="source" :items="documents" return-object item-value="id" item-text="name"
                  label="Document sélectionné"></v-select>
        <div v-for="(division, index) in divisions" :key="index" class="mt-2">
          <v-divider></v-divider>
          <p class="body-2">Document #{{ index + 1 }}</p>
          <v-select :items="attachments_types" item-text="value" item-value="value" v-model="divisions[index].type"
                    label="Type de fichier"></v-select>
          <v-text-field label="Page" v-model="divisions[index].pages">
            <template v-slot:append-outer>
              <v-btn icon @click.once="removeDivision(index)" color="red">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <v-btn color="primary" @click="addDivision" text>
          <v-icon>mdi-plus</v-icon>
          Ajouter un document
        </v-btn>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="createDocuments" :disabled="loading" color="primary">Créer les documents</v-btn>
        <v-btn @click="createForSet" :disabled="loading" color="primary">Générer pour le set</v-btn>
        <v-progress-circular color="primary" indeterminate v-if="loading"></v-progress-circular>
        <v-btn @click="reset">Reset</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SplitDocumentDialog',
  props: {
    dossier: Object,
  },
  data() {
    return {
      source: null,
      divisions: [],
      dialog: false,
      loading: false,
    };
  },
  methods: {
    addDivision() {
      this.divisions.push({
        type: "",
        pages: "",
      })
    },
    removeDivision(index) {
      this.divisions.splice(index, 1);
    },
    async createDocuments() {
      let payload = {
        source: this.source,
        divisions: this.divisions,
        dossier: this.dossier.id,
        template: null,
      }
      this.loading = true;
      let response = await this.$store.dispatch("attachments/diviseDocuments", payload);
      if(response) {
        this.$store.dispatch("annonce/annonceSuccess", response);
      }
      this.loading = false;
      this.dialog = false;
      this.$root.$emit("refresh");
    },
    async createForSet() {
      let payload = {
        source: this.source,
        dossier: this.dossier.id,
        template: "set_administratif",
      }
      this.loading = true;
      let response = await this.$store.dispatch("attachments/diviseDocuments", payload);
      if(response) {
        this.$store.dispatch("annonce/annonceSuccess", response);
      }
      this.loading = false;
      this.dialog = false;
      this.$root.$emit("refresh");
    },
    reset() {
      this.source = null;
      this.divisions = [];
      this.dialog = false;
      this.loading = false;
    }
  },

  computed: {
    documents() {
      let documents = [];
      let filteredDocuments = this.dossier.documents.filter(document => document.unsigned_file?.toLowerCase()?.endsWith(".pdf"));
      for (let document of filteredDocuments) {
        documents.push({type: "document", id: document.id, name: document.model_doc.name});
      }
      let filteredAttachments = this.dossier.attachments.filter(attachment => attachment.file.toLowerCase().endsWith(".pdf"));
      for (let attachment of filteredAttachments) {
        documents.push({type: "attachment", id: attachment.id, name: attachment.label});
      }
      return documents;
    },
    attachments_types() {
      return this.$store.getters["select/attachments_types"];
    },

  }
}
</script>
