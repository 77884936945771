<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th>Origine</th>
        <th>Montant</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="financement in financements" :key="financement.id">
        <td>{{ financement.origine }}</td>
        <td>{{ financement.montant }} €</td>
        <td>
          <EditFinancementDialog :financement="financement" @reload="$root.$emit('reload-financements')"/>
        </td>
        <td><v-icon @click="deleteFinancement(financement.id)" color="red">mdi-trash-can</v-icon></td>
      </tr>
      <tr>
        <td class="font-weight-bold">Total</td>
        <td>{{dossier.total_ressources}} €</td>
        <td></td>
      </tr>
      <tr>
        <td v-if="customOrigine">
          <v-text-field type="text" :rules="[value => value !== null]" label="Origine" v-model="financementModel.origine"></v-text-field>
        </td>
        <td v-else>
          <v-select :rules="[value => value !== null]" label="Origine" :items="origines_financement" item-value="value" item-text="value" v-model="financementModel.origine"></v-select>
        </td>
        <td>
          <v-text-field :rules="[value => value !== null]" type="number" step="0.01" label="Montant" v-model="financementModel.montant"></v-text-field>
        </td>
        <td>
          <v-icon @click="addFinancement" large color="primary">mdi-plus</v-icon>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <v-checkbox v-model="customOrigine" label="Entrer l'origine manuellement"></v-checkbox>
        </td>
      </tr>

      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import EditFinancementDialog from "@/views/EditFinancementDialog.vue";

export default {
  name: 'Financements',
  components: {EditFinancementDialog},
  props: {
    dossier: {},
  },
  async mounted() {
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  data() {
    return {
      customOrigine: false,
      financementModel: {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      }
    }
  },
  methods: {
    async addFinancement() {
      this.financementModel.dossier = this.dossier.id;
      let financement = await this.$store.dispatch("financement/postFinancement", this.financementModel);
      if(!financement) return;
      this.financementModel = {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      }
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      this.$root.$emit("reload-financements");
      await this.$store.dispatch("annonce/annonceSuccess", "Financement ajouté.");
    },
    async deleteFinancement(financementId) {
      await this.$store.dispatch("financement/deleteFinancement", {id: financementId});
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      this.$root.$emit("reload-financements");
      await this.$store.dispatch("annonce/annonceSuccess", "Financement supprimé.");

    },
  },
  computed: {
    financements() {
      return this.dossier.financements.filter((f) => f.origine.toLowerCase().indexOf("subvention") === -1
          && f.origine.toLowerCase().indexOf("apport personnel") === -1
          && f.origine.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf("pret") === -1);
    },
    origines_financement() {
      return this.$store.getters["select/origines_financement"];
    }
  }
}
</script>