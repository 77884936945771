<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th>Paramètre</th>
          <th>Éligible</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Cas général</td>
          <td>
            <v-checkbox hide-details v-model="parameters.cas_general"></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>Mission AISFT</td>
          <td>
            <v-checkbox hide-details v-model="parameters.mission_aisft"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Touche RSA : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.rsa"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Dégradation supérieure à 0.55</td>
          <td>
            <v-checkbox hide-details v-model="parameters.degradation"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Logement situé en opération programmée : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.operation_programme"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Personne âgée de plus de 65 ans : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.senior"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Traitement termites : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.termites"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Réduction vulnérabilité sismique : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.sismique"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Travaux accessibilité : Oui/Non</td>
          <td>
            <v-checkbox hide-details v-model="parameters.accessibilite"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Amiante</td>
          <td>
            <v-checkbox hide-details v-model="parameters.amiante"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Mise au norme assainissement</td>
          <td>
            <v-checkbox hide-details v-model="parameters.assainissement"></v-checkbox>
          </td>
        </tr>
        <tr v-if="dossier.type_dossier === 'AAH'">
          <td>Performance énergétique</td>
          <td>
            <v-checkbox hide-details v-model="parameters.performance_energetique"></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>A un prêt Martinique Habitat (génére un emploi)</td>
          <td>
            <v-checkbox hide-details v-model="parameters.martinique_habitat"></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>A une subvention CAF</td>
          <td>
            <v-checkbox hide-details v-model="parameters.caf"></v-checkbox>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="align-content: center; text-align: center">
            <v-btn color="primary" class="white--text" @click="validateParameters" :disabled="updating">Valider les paramètres</v-btn>
            <v-progress-circular indeterminate color="primary" v-if="updating"></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: 'Configuration',
  props: {
    dossier: {},
  },
  mounted() {
    this.initializeParameters();
    let that = this;
    this.$root.$on("reload-financements", function () {
      that.initializeParameters();
    })
  },
  data() {
    return {
      updating: false,
      parameters: {
        rsa: false,
        degradation: false,
        operation_programme: false,
        senior: false,
        termites: false,
        sismique: false,
        accessibilite: false,
        amiante: false,
        assainissement: false,
        performance_energetique: false,
        martinique_habitat: false,
        cas_general: false,
        mission_aisft: false,
        caf: false,
      },
    }
  },
  methods: {
    initializeParameters() {
      let dossier = this.selected_dossier !== null ? this.selected_dossier : this.dossier;
      this.parameters =  {
        cas_general: dossier?.financements.find((financement) => financement.origine === "Cas général") !== undefined,
        rsa: dossier?.financements.find((financement) => financement.origine === "Bénéficiaire touche le RSA") !== undefined,
        degradation: dossier?.financements.find((financement) => financement.origine === "Dégradation supérieure à 0.55") !== undefined,
        operation_programme: dossier?.financements.find((financement) => financement.origine === "Logement situé en opération programmée") !== undefined,
        senior: dossier?.financements.find((financement) => financement.origine === "Personne âgée de plus de 65 ans") !== undefined,
        termites: dossier?.financements.find((financement) => financement.origine === "Traitement termites") !== undefined,
        sismique: dossier?.financements.find((financement) => financement.origine === "Réduction vulnérabilité sismique") !== undefined,
        accessibilite: dossier?.financements.find((financement) => financement.origine === "Travaux accessibilité") !== undefined,
        amiante: dossier?.financements.find((financement) => financement.origine === "Présence d'amiante") !== undefined,
        assainissement: dossier?.financements.find((financement) => financement.origine === "Mise au norme assainissement") !== undefined,
        performance_energetique: dossier?.financements.find((financement) => financement.origine === "Performance énergétique") !== undefined,
        martinique_habitat: dossier?.honoraires.find((honoraire) => honoraire.origine === "Frais du prêt Martinique Habitat") !== undefined,
        mission_aisft: dossier?.honoraires.find((honoraire) => honoraire.origine === "Mission AISFT") !== undefined,
        caf: dossier?.financements.find((financement) => financement.origine === "Subvention CAF") !== undefined,

      }
    },
    async validateParameters() {
      this.updating = true;
      let result = await this.$store.dispatch("financement/validateParameters", {dossier: this.dossier.id, parameters: this.parameters});
      if(result.message !== "OK") {
        await this.$store.dispatch("annonce/annonceError", result.message);
      }
      await this.$store.dispatch("annonce/annonceSuccess", "Plan de financement mis à jour")
      let financements = await this.$store.dispatch("financement/fetchFinancementsByDossierId", this.dossier.id);
      if(financements !== undefined) {
        this.$store.commit("dossier/setFinancements", financements);
      }
      let honoraires = await this.$store.dispatch("honoraire/fetchHonorairesByDossierId", this.dossier.id);
      if(honoraires !== undefined) {
        this.$store.commit("dossier/setHonoraires", honoraires);
      }
      this.$root.$emit("reload-financements");
      this.updating = false;

    }
  },
  computed: {
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    }
  },
  watch: {
    parameters(oldValue, newValue) {
      if(oldValue.rsa === false && newValue.rsa === true) {
        oldValue.senior = false;
      }
      if(oldValue.senior === false && newValue.senior === true) {
        oldValue.rsa = false;
      }
    }
  }
}
</script>
<style scoped>
.timeline > ol > li:last-child {
  display: none !important;
}
.v-simple-table__wrapper>table>:not(caption)>*>* {
  padding: 0 !important;
}
.v-input--checkbox {
  padding-top: 0;
  padding-bottom: 4px;
}
</style>