<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Contrôleur DEAL
            </span><br>
      <span v-if="selectedDossier.controleur_deal" class="body-2">
        {{ selectedDossier.controleur_deal}}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le contrôleur DEAL</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-select :items="controleurs_deal" item-value="value" item-text="value" label="Nom du contrôleur DEAL"
                          v-model="nomControleurDeal"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateControleurDeal">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ControleurDEAL',
  props: {
    selectedDossier: Object,
  },
  async mounted() {
    if (this.$store.getters["select/selects"].length === 0) {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  data() {
    return {
      nomControleurDeal: this.selectedDossier.controleur_deal,
      dialog: false,
    }
  },
  computed: {
    controleurs_deal() {
      return this.$store.getters["select/controleurs_deal"];
    }
  },
  methods: {
    async updateControleurDeal() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, controleur_deal: this.nomControleurDeal});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Nom du contrôleur DEAL mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  }
}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>