<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Historique</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!loading" class="grey lighten-4">
      <v-sheet max-height="100" id="new-comment-sheet" class="overflow-y-auto grey lighten-4">
        <v-form class="d-flex">
          <v-textarea dense :rows="2" flat auto-grow v-model="message"></v-textarea>
          <v-btn color="primary" @click="addCommentaire" icon>
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-form>
      </v-sheet>
      <v-sheet v-if="pinned_comments.length > 0" id="pinned-commentaire-sheet" class="overflow-y-auto grey lighten-4">
        <h2>MESSAGES IMPORTANTS</h2>
        <v-card class="primary lighten-4 ma-1 pa-4" v-for="(item, index) in pinned_comments" :key="index">
          <h4 v-if="item.created_by !== null">{{ item.fullname }} <span
              class="font-italic grey--text">{{ getReadableDateTime(item.date_creation) }}</span></h4>
          <pre style=" margin:0; font-family: Roboto,serif; white-space: pre-wrap">{{ item.message }}</pre>
          <v-row class="mt-2">
            <v-dialog v-if="isAdmin" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn icon v-on="on" v-bind="attrs" class="red white--text" style="margin: 4px;">
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline red lighten-1 white--text">
                  Supprimer le commentaire
                </v-card-title>
                <v-card-text class="body-1 mt-2">
                  Confirmer la suppression du commentaire ?
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="deleteCommentaire(item)" text color="red">Supprimer le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-if="isAdmin" v-model="editDialog" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn icon v-on="on" @click="setEditText(item)" v-bind="attrs" class="primary white--text ma-1">
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline primary lighten-1 white--text">
                  Modifier le commentaire
                </v-card-title>
                <v-textarea class="pa-4" label="Commentaire" v-model="messageEdit"></v-textarea>
                <v-card-actions>
                  <v-btn @click="editCommentMessage()" text color="primary">Modifier le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn @click="changePinComment(item)" v-if="!item.pinned" icon class="blue white--text ma-1">
              <v-icon color="white">mdi-pin</v-icon>
            </v-btn>

            <v-btn @click="changePinComment(item)" v-else icon class="orange white--text ma-1">
              <v-icon color="white">mdi-pin-off</v-icon>
            </v-btn>
          </v-row>
        </v-card>
        <v-divider inset class="my-2" style="border-bottom: black solid 2px"></v-divider>
      </v-sheet>

      <v-sheet id="commentaire-sheet" class="overflow-y-auto grey lighten-4">
        <v-card class="primary lighten-4 ma-1 pa-4" v-for="(item, index) in unpinned_comments" :key="index">
          <h4 v-if="item.created_by !== null">{{ item.fullname }} <span
              class="font-italic grey--text">{{ getReadableDateTime(item.date_creation) }}</span></h4>
          <pre style=" margin:0; font-family: Roboto,serif; white-space: pre-wrap">{{ item.message }}</pre>
          <v-row class="mt-2">
            <v-dialog v-if="isAdmin" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn icon v-on="on" v-bind="attrs" class="red white--text" style="margin: 4px;">
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline red lighten-1 white--text">
                  Supprimer le commentaire
                </v-card-title>
                <v-card-text class="body-1 mt-2">
                  Confirmer la suppression du commentaire ?
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="deleteCommentaire(item)" text color="red">Supprimer le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-if="isAdmin" v-model="editDialog" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-btn icon v-on="on" @click="setEditText(item)" v-bind="attrs" class="primary white--text ma-1">
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline primary lighten-1 white--text">
                  Modifier le commentaire
                </v-card-title>
                <v-textarea class="pa-4" label="Commentaire" v-model="messageEdit"></v-textarea>
                <v-card-actions>
                  <v-btn @click="editCommentMessage()" text color="primary">Modifier le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn @click="changePinComment(item)" v-if="!item.pinned" icon class="blue white--text ma-1">
              <v-icon color="white">mdi-pin</v-icon>
            </v-btn>

            <v-btn @click="changePinComment(item)" v-else icon class="orange white--text ma-1">
              <v-icon color="white">mdi-pin-off</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-sheet>
    </v-card-text>
    <v-card-text v-else class="d-flex align-center justify-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-card-text>
  </v-card>
</template>
<script>
import {AuthMixin} from "@/mixins/AuthMixin";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'Commentaires',
  mixins: [AuthMixin, DateUtilMixin],
  props: {
    dossier: Object,
  },
  async mounted() {
    this.loading = true;
    this.commentaires = await this.$store.dispatch("dossier/commentaires/getByDossierId", this.dossier);
    this.loading = false;
    this.$root.$on("reload-comments", () => this.loadCommentaires());
  },
  data() {
    return {
      loading: false,
      commentaires: [],
      message: null,
      messageEdit: null,
      editDialog: false,
      commentaireEdited: null,
    }
  },
  methods: {
    async loadCommentaires() {
      this.loading = true;
      this.commentaires = await this.$store.dispatch("dossier/commentaires/getByDossierId", this.dossier);
      this.loading = false;
    },
    async addCommentaire() {
      let commentaire = {
        user: this.logged_user.id,
        dossier: this.dossier.id,
        message: this.message,
      }
      let result = await this.$store.dispatch("dossier/commentaires/postCommentaire", commentaire);
      if (result) {
        this.message = "";
        this.$store.dispatch("annonce/annonceSuccess", "Commentaire ajouté.");
        this.loadCommentaires();
      }
    },
    async deleteCommentaire(commentaire) {
      let result = await this.$store.dispatch("dossier/commentaires/deleteCommentaire", commentaire);
      if (result === "success") {
        this.loadCommentaires();
        this.$store.dispatch("annonce/annonceSuccess", "Commentaire supprimé.")
      }
    },
    async editCommentMessage() {
      let result = await this.$store.dispatch("dossier/commentaires/patchCommentaire", {
        id: this.commentaireEdited,
        message: this.messageEdit
      });
      if (result) {
        this.$store.dispatch("annonce/annonceSuccess", "Commentaire mis à jour.");
        this.editDialog = false;
        this.loadCommentaires();
      }
    },
    setEditText(item) {
      this.messageEdit = item.message;
      this.commentaireEdited = item.id;
    },
    async changePinComment(item) {
      let result = await this.$store.dispatch("dossier/commentaires/patchCommentaire", {
        pinned: !item.pinned,
        id: item.id,
      });
      if (result) {
        this.$store.dispatch("annonce/annonceSuccess", !item.pinned ? "Commentaire épinglé." : "Commentaire dépinglé.");
        this.editDialog = false;
        this.loadCommentaires();
      }
    }
  },
  computed: {
    pinned_comments() {
      return this.commentaires.filter((c) => c.pinned);
    },
    unpinned_comments() {
      return this.commentaires.filter((c) => !c.pinned);
    }
  }
}
</script>
<style>

.timeline > ol > li:last-child {
  display: none !important;
}

</style>